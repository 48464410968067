import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { apiGet, apiPut } from '../../functionsAPI'
import { Grid, Input, Slider } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Users = () => {
    const [value, setValue] = useState(null);

    const onSubmit = (e) => {
        e.preventDefault()
        const params = {
            conf: value ? value : 0,
        }
        apiPut(`/profile/conf`, null, params)
            .then((res) => {
                console.log(res)
                toast.success('Accuracy Level Updated.')
            })
            .catch((err) => {
                console.log(err)
                toast.error(err)
            });
    }

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
    }

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    }

    const getProfile = () => {
        apiGet('/profile')
            .then((res) => {
                console.log(res)
                setValue(res.data.conf)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <form onSubmit={onSubmit} className="mt-5">
                <Grid
                    container
                    spacing={3}
                    direction="column"
                >
                    <Grid item xs={6}>
                        <Typography id="input-slider" gutterBottom>
                            Confidence Threshold
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <BarChartIcon />
                            </Grid>
                            <Grid item xs>
                                <Slider
                                    value={typeof value === 'number' ? value : 0}
                                    onChange={handleSliderChange}
                                    aria-labelledby="input-slider"
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    value={value}
                                    margin="dense"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                        step: 1,
                                        min: 0,
                                        max: 100,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Link to="/scans" className='m-3'>
                            <Button
                                type="submit"
                                variant="contained"
                            >
                                Scan List
                            </Button>
                        </Link>
                        <Button
                            className='m-3'
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default Users
