import React, { useEffect, useState, useMemo } from 'react'
import languages from '../languages'
import { Badge, Col, Row } from 'react-bootstrap'
import {
    IconButton,
    AppBar,
    Dialog,
    Snackbar,
    Slide,
    Toolbar,
    Typography,
} from '@mui/material';
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import CloseIcon from '@mui/icons-material/Close';
import { TabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import Autocomplete from '@mui/material/Autocomplete';
import { Alert, Button, CircularProgress, Tooltip, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { apiDelete, apiPost } from '../functionsAPI';
import LoadingButton from '@mui/lab/LoadingButton';
import validator from 'validator'
import { apiGet } from '../functionsAPI'
import { DropzoneArea } from 'react-mui-dropzone'
import EditableTextArea from '../components/EditableTextArea'
import TextField from '@mui/material/TextField';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useHistory } from 'react-router-dom'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const StyledTab = styled(Tab)`
    font-family: IBM Plex Sans, sans-serif;
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${blue[400]};
    }
  
    &:focus {
      color: #fff;
      border-radius: 3px;
      outline: 2px solid ${blue[200]};
      outline-offset: 2px;
    }
  
    &.${tabClasses.selected} {
      background-color: ${blue[50]};
      color: ${blue[600]};
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

const StyledTabPanel = styled(TabPanel)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `;

const StyledTabsList = styled(TabsList)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OcrWidget(props) {
    const [conf, setConf] = useState(75)
    const history = useHistory()
    const profile = JSON.parse(localStorage.getItem('oocUser'))
    const activeProfile = localStorage.getItem('oocActiveProfile')
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<CircularProgress size={30} />) : ('')
    // helpers
    const [key, setKey] = useState(0)
    const [method, setMethod] = useState(0)
    const [isValidUrl, setIsValidUrl] = useState(true)
    // const [image, setImage] = useState(null);
    // const [base64, setBase64] = useState(null);
    const [url, setURL] = useState('');
    const [language, setLanguage] = useState(props.value ? [props.value] : [languages[22]]);

    const [result, setResult] = useState('')
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [files, setFiles] = useState([])
    const [multipleFiles, setMulipleFiles] = useState(false)
    const [converted, setConverted] = useState(false)
    const [articleName, setArticleName] = useState(null)
    const [content, setContent] = useState([])
    const [previewImage, setPreviewImage] = useState(null)
    const [isImage, setIsImage] = useState(false)
    const [pdfUrl, setPdfUrl] = useState(false)

    const [articleId, setArticleId] = useState(null)
    const [openContent, setOpenContent] = useState(false)
    const [count, setCount] = useState(0)
    const [isPdf, setIsPdf] = useState(false)
    const [allowedLimit, setAllowedLimit] = useState(0)
    const [remainingLimit, setRemainingLimit] = useState(0)

    let plan;

    if (activeProfile === 'Enterprise') {
        plan = 'Enterprise'
    } else if (profile?.plan?.plan === 'Enterprise') {
        plan = 'Enterprise'
    } else if (profile?.plan?.plan === 'Professional') {
        plan = 'Professional'
    } else {
        plan = 'Free'
    }

    const imageFileType = ['image/*'];
    const pdfFileType = ['application/pdf'];
    const enterpriseAllowedFileType = ['image/*', 'application/pdf'];

    let fileType;
    let file_limit;
    if (profile) {
        if (activeProfile === 'Enterprise') {
            fileType = enterpriseAllowedFileType;
            file_limit = 50
        } else if (profile?.plan?.plan === 'Enterprise' || profile?.plan?.plan === 'Professional') {
            fileType = enterpriseAllowedFileType;
            file_limit = 50
        } else {
            fileType = imageFileType;
            file_limit = 1
        }
    } else {
        file_limit = 1
        fileType = imageFileType;
    }

    const [allowedFileType, setAllowedFileType] = useState(fileType);
    const [filesLimit, setFilesLimit] = useState(file_limit)

    const [fileSizeLimit, setFileSizeLimit] = useState(1 * 1048576)
    const [paragraphWords, setParagraphWords] = useState([])
    const [type, setType] = useState(null)
    const [deleteToggle, setDeleteToggle] = useState(false)

    const [uploadDisabled, setUploadDisabled] = useState(false);

    const handleClickOpenContent = () => {
        setOpenContent(true);
        getArticleDetails(articleId)
    };

    const handleCloseContent = () => {
        setOpenContent(false);
        setArticleName(null)
        setConf(75)
        setContent([])
        setPreviewImage(null)
        setIsImage(false)
        setPdfUrl(null)
    };

    const handelUrlChange = (e) => {
        let value = e.target.value
        setURL(value)
        if (validator.isURL(value)) {
            setIsValidUrl(true)
        } else {
            setIsValidUrl(false)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenError(false);
    };

    useEffect(() => {
        getUserScanLimit()
        getAllSubscriptions()
        // eslint-disable-next-line
    }, [])

    const getUserScanLimit = () => {
        let params = {}

        if (profile?.parent?.id && localStorage.getItem('oocActiveProfile') === "Enterprise") {
            params.parent_id = profile.parent.id;
        }

        apiGet('/user-scan-limit', params)
            .then((res) => {
                setAllowedLimit(res.data.total_allowed_scans)
                setRemainingLimit(res.data.total_remaining_scans)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getAllSubscriptions = () => {
        apiGet('/subscriptions')
            .then((res) => {
                const getFileLimit = (planName) => res.data.find(item => item.name === planName).file_limit * 1048576;

                if (profile) {
                    const { plan } = profile.plan;
                    const isSubUser = profile.parent && activeProfile === 'Enterprise';

                    if (plan === 'Free') {
                        if (isSubUser) {
                            setFileSizeLimit(getFileLimit('Enterprise'));
                        } else {
                            setFileSizeLimit(getFileLimit('Free'));
                        }
                    } else if (plan === 'Professional') {
                        if (isSubUser) {
                            setFileSizeLimit(getFileLimit('Enterprise'));
                        } else {
                            setFileSizeLimit(getFileLimit('Professional'));
                        }
                    } else if (plan === 'Enterprise') {
                        setFileSizeLimit(getFileLimit('Enterprise'));
                    }
                }else {
                    setFileSizeLimit(getFileLimit('Free'));
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // Convert Submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccess(false);
        setResult(false);
        setError(false);
        setLoading(true);
        let data = null
        let apiURL = null

        if (language.length > 0) {
            var selectedLanguagesArray = []
            language.map((item) => {
                return selectedLanguagesArray.push(item.value)
            })

            const selectedLanguages = selectedLanguagesArray.join("+")
            if (method === 0) {
                if (!isPdf) {
                    if (files.length > 0 && files.length < 2) {
                        apiURL = '/articles/image';
                        var formData = new FormData()
                        formData.append('article', files[0], articleName)
                        activeProfile === 'Enterprise' && formData.append('parent_id', profile.parent.id)
                        profile?.plan?.plan === 'Enterprise' && formData.append('parent_id', profile.id)
                        formData.append('language', selectedLanguages)

                        apiPost(apiURL, formData)
                            .then((res) => {
                                setUploadDisabled(true)
                                setArticleId(res.data.id)
                                // getArticleDetails(res.data.id)
                                const _key = key + 1
                                setKey(_key)
                                setResult(res.data.text)
                                getUserScanLimit()
                                setConverted(true)
                                setLoading(false)
                            })
                            .catch((err) => {
                                console.log(err);
                                setError(err)
                                setLoading(false)
                            });

                    } else if (files.length > 1) {
                        apiURL = '/batch';
                        var batchData = new FormData()
                        files.forEach(file => {
                            batchData.append('files', file);
                        })
                        batchData.append('language', selectedLanguages)
                        activeProfile === 'Enterprise' && batchData.append('parent_id', profile.parent.id)
                        profile?.plan?.plan === 'Enterprise' && batchData.append('parent_id', profile.id)

                        apiPost(apiURL, batchData)
                            .then((res) => {
                                setUploadDisabled(true)
                                setConverted(true)
                                setLoading(false)
                                getUserScanLimit()
                            })
                            .catch((err) => {
                                console.log(err);
                                toast.error(err)
                                setLoading(false)
                            });

                    } else {
                        toast.error('Please select file')
                        setLoading(false)
                    }
                } else {
                    apiURL = '/pdf';
                    var pdfData = new FormData()
                    pdfData.append('file', files)
                    pdfData.append('language', selectedLanguages)
                    activeProfile === 'Enterprise' && pdfData.append('parent_id', profile.parent.id)
                    profile.plan.plan === 'Enterprise' && pdfData.append('parent_id', profile.id)

                    apiPost(apiURL, pdfData)
                        .then((res) => {
                            setUploadDisabled(true)
                            setConverted(true)
                            setLoading(false)
                            getUserScanLimit()
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(err)
                            setLoading(false)
                        });
                }
            } else {
                if (url) {
                    data = {
                        url,
                        language: selectedLanguages
                    }
                    const params = {
                        parent_id: profile?.plan?.plan === 'Enterprise' ? profile.id : activeProfile === 'Enterprise' ? profile.parent.id : null
                    }
                    apiURL = '/articles/url';

                    apiPost(apiURL, data, params)
                        .then((res) => {
                            setUploadDisabled(true)
                            setArticleId(res.data.id)
                            // getArticleDetails(res.data.id)
                            setConverted(true)
                            const _key = key + 1
                            setKey(_key)
                            setResult(res.data.text)
                            setLoading(false)
                        })
                        .catch((err) => {
                            console.log(err);
                            setError(err)
                            setLoading(false)
                        });
                } else {
                    setError('Please enter URL and a language')
                    setLoading(false)
                }
            }
        } else {
            toast.error('Please Select a language')
            setLoading(false)
        }
    }

    async function fetchAndConvertToBlob(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch resource');
            }

            const blob = await response.blob();
            return blob;
        } catch (error) {
            toast.error('Error fetching or converting to Blob')
            return null;
        }
    }

    const getArticleDetails = (id) => {
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setArticleName(res.data.name)
                if (res.data.user) {
                    setConf(res.data.user.conf);
                } else {
                    const storedConf = localStorage.getItem('conf');
                    if (storedConf !== null) {
                        setConf(storedConf);
                    } else {
                        setConf(75);
                    }
                }
                setContent(res.data.words)
                fetchAndConvertToBlob(res.data.url)
                    .then(blob => {
                        if (blob) {
                            if (blob.type === "application/pdf") {
                                setIsImage(false)
                                setPdfUrl(res.data.url)
                            } else {
                                setPreviewImage(blob);
                                setIsImage(true)
                            }
                        } else {
                            console.log('Error while converting image to blob.')
                            toast.error('Error while converting image to blob.')
                        }
                    });
            })
            .catch((err) => {
                console.log(err);
                setError(err)
            });
    }

    const handleRedirectToScan = () => {
        return history.push('/scans')
    }

    const handleFilesChange = (files) => {
        if (converted) {
            handleReset()
        }

        if (!files || files.length === 0) {
            setFilesLimit(file_limit)
            setAllowedFileType(fileType)
            return
        }

        const pdfFiles = files.filter((file) => file.type === 'application/pdf');
        if (pdfFiles.length > 1) {
            setCount(prevCount => prevCount - 1);
            toast.error('Only one PDF file can be uploaded.');
            return;
        }

        const containsPdf = pdfFiles.length === 1 ? pdfFiles[0] : undefined;

        if (containsPdf === undefined) {
            setFiles(files)
            setMulipleFiles(true)
            setIsPdf(false)
            setAllowedFileType(imageFileType)
        } else {
            const fileReader = new FileReader();
            fileReader.onload = async () => {
                const typedArray = new Uint8Array(fileReader.result);
                try {
                    const pdf = await pdfjsLib.getDocument(typedArray).promise;
                    const numPages = pdf.numPages;
                    if (numPages > 50) {
                        setCount(prevCount => prevCount - 1)
                        toast.error('The uploaded PDF exceeds the maximum page limit of 50.')
                    }
                } catch (error) {
                    toast.error('Error reading the PDF file.')
                }
            };
            fileReader.readAsArrayBuffer(containsPdf);

            setFilesLimit(1)
            setFiles(files[files.length - 1])
            setIsPdf(true)
            setAllowedFileType(pdfFileType)
        }

        if (2 > files.length > 0 && files.length !== 0) {
            setMulipleFiles(false)
            setArticleName(files[0].name)
        } else {
            setMulipleFiles(true)
            setArticleName(null)
        }
    }

    const handleReset = () => {
        setCount(count => count + 1)
        setOpenContent(false)
        setContent([])
        setPreviewImage(null)
        url && window.scrollTo(0, 0)
        setURL('')
        setConverted(false)
        setMulipleFiles(false)
        setIsPdf(false)
        setAllowedFileType(fileType)
        setUploadDisabled(false)
    }

    const getContent = (id, type, name) => {
        setType(type)
        setArticleName(name)
        setLoading(true)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setParagraphWords(res.data.words)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                toast.error('Error fetching words')
                setLoading(false)
            });
    }

    const viewFile = (id) => {
        setLoading(true)
        apiGet(`/articles/${id}`)
            .then((res) => {
                const link = document.createElement('a');
                link.href = res.data.url;
                link.target = '_blank'
                link.click();
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                toast.error(err)
                setLoading(false)
            });
    }

    const handleDeleteOpen = (articleId) => {
        setDeleteToggle(true)
        setArticleId(articleId)
    }

    const handleDeleteClose = () => {
        setDeleteToggle(false)
        setArticleId(null)
    }

    const sortedWords = useMemo(() => {
        return paragraphWords.sort((a, b) => {
            if (a.block_num !== b.block_num) return a.block_num - b.block_num;
            if (a.par_num !== b.par_num) return a.par_num - b.par_num;
            if (a.line_num !== b.line_num) return a.line_num - b.line_num;
            return a.word_num - b.word_num;
        });
    }, [paragraphWords]);

    const groupedWords = useMemo(() => {
        const groups = sortedWords.reduce((acc, word) => {
            const { block_num, par_num } = word;
            if (!acc[block_num]) acc[block_num] = {};
            if (!acc[block_num][par_num]) acc[block_num][par_num] = [];
            acc[block_num][par_num].push(word);
            return acc;
        }, {});
        return Object.values(groups).map(block => Object.values(block));
    }, [sortedWords]);

    const arrangeWords = (groupedWords) => {
        var data = '';
        const groupedWordsArray = Object.values(groupedWords)
        groupedWordsArray.forEach((parNum) => {
            const parNumArray = Object.values(parNum)
            parNumArray.forEach((para) => {
                const paraArray = Object.values(para)
                paraArray.forEach((word) => {
                    data += word.corrected_text ?? word.generated_text
                    data += ' '
                })
                data += '\n\n'
            })
        });

        if (type === 'copy') {
            copyToClipboard(data);
        } else if (type === 'txt') {
            downloadTxt(data, articleName)
        } else if (type === 'docx') {
            downloadDoc(data, articleName)
        }
    }

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(data)
        const message = 'Copied to clipboard.'
        toast.success(message)
    }

    useEffect(() => {
        if (groupedWords.length > 0) {
            arrangeWords(groupedWords)
        }
        //eslint-disable-next-line
    }, [groupedWords])

    const downloadTxt = (data, articleName) => {
        try {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${articleName}.txt`)
            document.body.appendChild(link);
            link.click();
            link.remove()
            toast.success('TXT file Downloaded')
        } catch (error) {
            toast.error(0, 'Error creating download link:');
        }
    }

    const downloadDoc = (data, articleName) => {
        try {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/msword' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${articleName}.docx`)
            document.body.appendChild(link);
            link.click();
            link.remove()
            toast.success('Doc file Downloaded')
        } catch (error) {
            toast.error('Error creating download link:');
        }
    }

    const deleteItem = (articleId) => {
        setLoading(true)
        apiDelete(`/articles/${articleId}`)
            .then(res => {
                const message = 'Article Deleted.'
                toast.success(message)
                setDeleteToggle(false)
                setLoading(false)
                handleCloseContent()
            }).catch(error => {
                console.log(error)
                toast.error('Error while deleting file')
                setLoading(false)
            })
    }

    return (
        <div>
            <Row className='justify-content-center'>
                <Col md={8} className="card">
                    <Tabs defaultValue={0} onChange={(e, val) => { setMethod(val) }}>
                        <StyledTabsList>
                            <StyledTab>File Upload</StyledTab>
                            <StyledTab>Paste URL</StyledTab>
                        </StyledTabsList>
                        <StyledTabPanel value={0}>
                            <Row className='justify-content-center'>
                                <Col md={8}>
                                    <DropzoneArea
                                        key={count}
                                        acceptedFiles={allowedFileType}
                                        filesLimit={filesLimit}
                                        showPreviewsInDropzone={false}
                                        showPreviews={true}
                                        previewText='Selected:'
                                        useChipsForPreview={true}
                                        showAlerts={['error']}
                                        maxFileSize={fileSizeLimit}
                                        Icon={UploadFileIcon}
                                        dropzoneClass={`border-thin ${uploadDisabled ? 'dropzone-disabled' : ''}`}
                                        dropzoneParagraphClass='text-muted'
                                        alertSnackbarProps={
                                            {
                                                anchorOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }
                                            }
                                        }
                                        dropzoneText={!uploadDisabled ? 'Select or Drag file here' : 'Click the button below to start a new scan'}
                                        onChange={handleFilesChange}
                                        dropzoneProps={{ disabled: uploadDisabled }}
                                    />
                                </Col>
                            </Row>
                        </StyledTabPanel>
                        <StyledTabPanel value={1}>
                            <Row className='justify-content-center'>
                                <Col md={8}>
                                    <TextField
                                        error={!isValidUrl}
                                        required
                                        fullWidth
                                        value={url}
                                        onChange={handelUrlChange}
                                        className='mt-4 mb-4'
                                        label="Enter URL"
                                        helperText={isValidUrl ? '' : 'Please enter a valid URL'}
                                        variant="outlined" />
                                    {url && isValidUrl && <img src={url} className="img-fluid mb-4 img-preview" alt='selected' />}
                                </Col>
                            </Row>
                        </StyledTabPanel>
                        <Row className='justify-content-center language'>
                            <Col md={8}>
                                {props.value ? (
                                    <p className='text-center'>Selected Language: {props.value.name}</p>
                                ) : (
                                    <Autocomplete
                                        multiple
                                        options={languages}
                                        value={language}
                                        className="mt-4"
                                        getOptionDisabled={(options) => (language.length >= 2 ? true : false)}
                                        getOptionLabel={option => option.name}
                                        onChange={(e, val) => setLanguage(val)}
                                        renderInput={(params) => <TextField {...params} required label="Language" />}
                                    />
                                )}
                            </Col>
                        </Row>

                        <div className="text-center mt-2 mb-3">
                            <span> Remaining Scans : {allowedLimit ? `${remainingLimit} (Out of ${allowedLimit})` : `... (Out of ...)`}</span>
                        </div>

                        <div className='text-center'>
                            {converted &&
                                <>
                                    <LoadingButton
                                        onClick={handleReset}
                                        loading={loading}
                                        disabled={method === 1 && !isValidUrl}
                                        loadingIndicator="Converting..."
                                        variant="contained"
                                        className='btn-convert'
                                        style={{ textTransform: 'capitalize', borderRadius: '15px' }}
                                    >
                                        New Scan
                                    </LoadingButton>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                            }

                            <LoadingButton
                                onClick={converted ? (multipleFiles || isPdf) ? handleRedirectToScan : handleClickOpenContent : handleSubmit}
                                loading={loading}
                                disabled={method === 1 && !isValidUrl}
                                loadingIndicator="Converting..."
                                variant="contained"
                                className='btn-convert'
                                style={{ textTransform: 'capitalize', borderRadius: '15px' }}
                            >
                                {converted ? (multipleFiles || isPdf) ? 'View Scans' : 'View Output' : 'Convert Now'}
                            </LoadingButton>
                            <p className='mt-5'>
                                {progress}
                            </p>
                            {((multipleFiles || isPdf) && converted) &&
                                <p className='mt-5'>
                                    {`Your ${isPdf ? 'pdf is' : 'files have been'} successfully uploaded and added to the OCR queue. Please visit the scans page to view the OCR output.`}
                                </p>}
                        </div>
                        {result ? (
                            <div className="result">
                                {success ? (
                                    <Snackbar anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }} open={open} autoHideDuration={3000} onClose={handleClose} >
                                        <Alert onClose={handleClose} severity="success" className='mt-4'>{success}</Alert>
                                    </Snackbar>
                                ) : ''}
                                {success ? (
                                    <Alert severity="success" className='mt-4'>{success}</Alert>
                                ) : ''}
                            </div>
                        ) : ''}
                        {error ? (
                            <Snackbar anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }} open={openError} autoHideDuration={3000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">{error}</Alert>
                            </Snackbar>

                        ) : ''}
                        {error ? (
                            <Alert severity="error" className='mt-4'>{error}</Alert>
                        ) : ''}
                    </Tabs>
                </Col>
            </Row>
            <Dialog fullScreen open={openContent} onClose={handleCloseContent} TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" onClick={handleCloseContent} aria-label="close">
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                        <Typography component="p" style={{ color: 'white' }} >
                            Content : {articleName}
                        </Typography>

                        <div className='ms-auto text-white'>
                            <Tooltip title="Copy to Clipboard">
                                <IconButton onClick={() => { getContent(articleId, 'copy', articleName) }}>
                                    <ContentCopyIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Txt Downlaod">
                                <IconButton onClick={() => { getContent(articleId, 'txt', articleName) }}>
                                    <ArticleIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Docx Download">
                                <IconButton onClick={() => { getContent(articleId, 'docx', articleName) }}>
                                    <DownloadIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                            {(plan === 'Enterprise' || plan === 'Professional') &&
                                <>
                                    <Tooltip title="Download File">
                                        <IconButton onClick={() => { viewFile(articleId) }}>
                                            <ImageIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete file">
                                        <IconButton onClick={() => handleDeleteOpen(articleId)}>
                                            <DeleteIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                        </div>
                    </Toolbar>
                    {progress}
                </AppBar>
                {progress}
                {converted && <EditableTextArea isImage={isImage} pdfUrl={pdfUrl} image={previewImage} data={content} conf={conf} articleId={articleId} setLoading={setLoading} />}
                {progress}
            </Dialog>
            <Dialog maxWidth='sm' fullWidth open={deleteToggle} onClose={handleDeleteClose} className="form-action" aria-labelledby="form-dialog-title">
                <DialogTitle component='h6'>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure you want to delete?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(articleId)} variant="contained" color="primary">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

OcrWidget.defaultProps = {
    value: null,
}