// API Call URL
export const URL = process.env.REACT_APP_OCR_CONVERTER_API_URL

// Client Id
export const CLIENT_ID = process.env.REACT_APP_OCR_CONVERTER_CLIENT_ID

// Stripe Key
export const STRIPE_KEY = process.env.REACT_APP_OCR_CONVERTER_STRIPE_KEY

// Price Id
export const PRICE_ID_PROFESSIONAL_IN = process.env.REACT_APP_OCR_CONVERTER_PRICE_ID_PROFESSIONAL_IN
export const PRICE_ID_PROFESSIONAL_OTHER = process.env.REACT_APP_OCR_CONVERTER_PRICE_ID_PROFESSIONAL_OTHER
export const PRICE_ID_ENTERPRISE_IN = process.env.REACT_APP_OCR_CONVERTER_PRICE_ID_ENTERPRISE_IN
export const PRICE_ID_ENTERPRISE_OTHER = process.env.REACT_APP_OCR_CONVERTER_PRICE_ID_ENTERPRISE_OTHER